import React, { useRef, useEffect } from 'react';
import { css } from '@emotion/core';
import Button from '../button';
import { Modal } from '../modal';

export const Preview = props => {
  const canvasRef = useRef();
  useEffect(() => {
    const context = canvasRef.current.getContext('2d');
    // I know it really isn't a buffer..
    const imageBuffer = new Image();

    imageBuffer.onload = () => {
      // draw cropped image
      const sourceOffsetX = props.sourceOffsetX;
      const sourceOffsetY = props.sourceOffsetY;
      const sourceWidth = props.sourceWidth;
      const sourceHeight = props.sourceHeight;
      const destOffsetX = 0;
      const destOffsetY = 0;
      const destWidth = props.width;
      const destHeight = props.height;

      context.drawImage(
        imageBuffer,
        sourceOffsetX,
        sourceOffsetY,
        sourceWidth,
        sourceHeight,
        destOffsetX,
        destOffsetY,
        destWidth,
        destHeight
      );
    };
    imageBuffer.src = props.image;
    //    useCropped(canvas.toDataURL());
  }, []);
  return (
    <Modal>
      <canvas
        ref={canvasRef}
        width={props.width}
        height={props.height}
        css={css`
          display: block;
          margin: 20px;
          border: solid 4px #111;
          border-radius: 25px;
          box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);
        `}
      />
      <Button onClick={() => props.setPreview(false)}>Close</Button>
    </Modal>
  );
};
