import React from 'react';
import { css } from '@emotion/core';

export const Stats = props => {
  return (
    <div
      css={css`
        border: dashed 3px royalblue;
        /* width: 250px; */
        position: fixed;
        display: none;
        background-color: #fff;
        top: 30px;
        right: 0px;
        font-size: 12px;
      `}
    >
      <ul
        css={css`
          list-style: none;
          margin: 5px;
        `}
      >
        <li>Case OffsetX: {props.caseOffsetX}</li>
        <li>Case OffsetY: {props.caseOffsetY}</li>
        <li>OffsetX: {props.offsetX}</li>
        <li>OffsetY: {props.offsetY}</li>
        <li>Left: {props.left}</li>
        <li>Top: {props.top}</li>
        <li>Resized W: {props.resizedWidth}</li>
        <li>Natural W: {props.naturalWidth}</li>
        <li>Natural H: {props.naturalHeight}</li>
      </ul>
    </div>
  );
};
