import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import { OrderDispatch } from '../order-context';
import Button from '../button';

import { Stats } from './stats';
import { PhoneSelect } from './phone-select';
import { Mask } from './mask';
import { DropImage } from './drop-image';
import { Modal } from '../modal';
import { Preview } from './preview';

const Info = styled.div`
  font-size: 15px;
  margin: 15px auto;
`;

const spin = keyframes`
to {
    transform: rotate(360deg);
  }
`;

const Loading = props => (
  <>
    <div
      css={css`
        border: 3px solid rgba(255, 111, 145, 0.2);
        border-top-color: #ff6f91;
        border-radius: 50%;
        width: 3em;
        height: 3em;
        margin: 10px auto 20px;
        animation: ${spin} 1s linear infinite;
      `}
    />
    {props.text}
  </>
);

const checkLinesStyles = css`
  background-color: #00c9a7;
  border-radius: 5px;
  height: 5px;
  position: absolute;
`;

const OrderAdded = props => (
  <>
    <div
      css={css`
        border: 3px solid #00c9a7;
        border-radius: 50%;
        width: 3em;
        height: 3em;
        margin: 10px auto 20px;
        position: relative;
      `}
    >
      <div
        css={css`
          width: 30px;
          top: 22px;
          left: 13px;
          transform: rotate(125deg);
          ${checkLinesStyles}
        `}
      />
      <div
        css={css`
          width: 15px;
          top: 29px;
          left: 8px;
          transform: rotate(35deg);
          ${checkLinesStyles}
        `}
      />
    </div>
    <div
      css={css`
        text-align: center;
        margin-bottom: 20px;
      `}
    >
      Order Added!
    </div>
    <Link to="/order/">
      <Button cssExtras={`margin-right: 10px;`}>Your Order</Button>
    </Link>
    <Button onClick={() => props.close()}>Create another case</Button>
  </>
);

export default ({ products }) => {
  const [image, useImage] = useState();
  const [maskElement, useMaskElement] = useState();
  const [maskTemplate, useMaskTemplate] = useState();

  const [stats, useStats] = useState({
    left: 0,
    top: 0,
    offsetX: 0,
    offsetY: 0,
    resizedWidth: 0,
    naturalWidth: 0,
    naturalHeight: 0,
  });

  const ordersDispatch = useContext(OrderDispatch);
  const [modal, useModal] = useState(false);
  const [preview, usePreview] = useState(false);

  /* Scaling DOWN to get the display size and offsets from the natural sizes */
  // The mask's whole natural width and height
  const maskNaturalWidth = 2000;
  const maskNaturalHeight = 2250;

  // The natural width and height of the case within the mask
  const caseNaturalWidth = maskTemplate ? maskTemplate.width : 0;
  const caseNaturalHeight = maskTemplate ? maskTemplate.height : 0;

  // The phone case in the mask is always centered so the offset of the case to the mask
  // is full length minus the case length (remaining space) divided by two
  const caseNaturalOffsetX = (maskNaturalWidth - caseNaturalWidth) / 2;
  const caseNaturalOffsetY = (maskNaturalHeight - caseNaturalHeight) / 2;

  // Ratio between the natural size of the mask image and the displayed image
  const maskRatio = maskElement
    ? maskNaturalWidth / maskElement.current.offsetWidth
    : 1;

  // Using this ratio find the display offset X + Y of the phone case in the mask
  const caseOffsetX = caseNaturalOffsetX / maskRatio;
  const caseOffsetY = caseNaturalOffsetY / maskRatio;

  /* Scaling back UP to get the size and offset to cut the image when submitted */
  // Difference between actual image size and size on screen
  const difference = stats.naturalWidth / stats.resizedWidth;

  // Actual offset
  const naturalOffsetX = stats.offsetX * difference;
  const naturalOffsetY = stats.offsetY * difference;

  const naturalWidthResize = (caseNaturalWidth / maskRatio) * difference;
  const naturalHeightResize = (caseNaturalHeight / maskRatio) * difference;

  let outOfBounds = false;
  if (stats.offsetX < 0 || stats.offsetY < 0) {
    outOfBounds = true;
  }
  const caseWidth = caseNaturalWidth / maskRatio;
  const caseHeight = caseNaturalHeight / maskRatio;
  if (maskTemplate && stats.resizedWidth - stats.offsetX < caseWidth) {
    outOfBounds = true;
  }

  const resizedHeight =
    stats.naturalHeight * (stats.resizedWidth / stats.naturalWidth);
  if (maskTemplate && resizedHeight - stats.offsetY < caseHeight) {
    outOfBounds = true;
  }

  const imageSubmitted = id => {
    useModal({ complete: true });
    const order = {
      id,
      thumbnail: `https://s3-ap-southeast-2.amazonaws.com/bananapark-images-temp/${id}`,
      product: maskTemplate.id,
      name: maskTemplate.name,
      quantity: 1,
      price: maskTemplate.price,
      imageMeta: {
        naturalWidthResize,
        naturalHeightResize,
        naturalOffsetX,
        naturalOffsetY,
        caseNaturalWidth,
      },
    };
    ordersDispatch({ type: 'add', value: order });
  };

  const submitImage = () => {
    const imageData = image.replace(/^data:image\/\w+;base64,/, '');
    const imageRoot =
      'https://11x0rkd7xc.execute-api.ap-southeast-2.amazonaws.com/dev';
    // const imageRoot = 'http://localhost:3000';
    const uploadUrl = `${imageRoot}/?width=${naturalWidthResize}&height=${naturalHeightResize}&offsetX=${naturalOffsetX}&offsetY=${naturalOffsetY}&resizeWidth=${caseNaturalWidth}`;
    useModal({ text: 'Uploading Image: 0%' });
    const request = new XMLHttpRequest();
    request.onreadystatechange = function() {
      if (request.readyState === 4 && request.status === 200) {
        const { id } = JSON.parse(request.responseText);
        imageSubmitted(id);
      }
    };
    request.open('POST', uploadUrl);
    request.upload.onprogress = progressEvent => {
      if (progressEvent.lengthComputable) {
        const percentComplete =
          (progressEvent.loaded / progressEvent.total) * 100;
        useModal({ text: `Uploading Image: ${Math.round(percentComplete)}%` });
      }
    };
    request.upload.onload = () =>
      useModal({ text: 'Processing image, this may take a few minutes.' });
    request.send(imageData);
  };

  return (
    <div
      css={css`
        margin-bottom: 50px;
      `}
    >
      {modal && (
        <Modal>
          {modal.complete ? (
            <OrderAdded
              close={() => {
                useModal(false);
                useImage();
                useMaskElement();
                useMaskTemplate();
              }}
            />
          ) : (
            <Loading text={modal.text} />
          )}
        </Modal>
      )}
      {!maskTemplate && <Info>1. Select your phone type:</Info>}
      <PhoneSelect
        products={products}
        setMaskImage={maskImage => useMaskTemplate(maskImage)}
      />
      {maskTemplate && !image && (
        <>
          <Info>
            2. Drag and drop your image here or click the drop area to open the
            file explorer:
          </Info>
          <DropImage setImage={droppedImage => useImage(droppedImage)} />
        </>
      )}
      {maskTemplate && image && (
        <>
          <Info>
            3. Drag your image into position and resize with the blue resize
            handle.
            <br /> When you are happy with your phone case click Add To Order!
          </Info>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
            `}
          >
            <Mask
              image={image}
              maskImage={maskTemplate.mask}
              maskRatio={maskRatio}
              caseOffsetX={caseOffsetX}
              caseOffsetY={caseOffsetY}
              setStats={newStats => useStats({ ...stats, ...newStats })}
              setMask={maskRef => useMaskElement(maskRef)}
              setImage={droppedImage => useImage(droppedImage)}
              outOfBounds={outOfBounds}
            />
            <Stats
              left={stats.left}
              top={stats.top}
              offsetX={stats.offsetX}
              offsetY={stats.offsetY}
              caseOffsetX={stats.caseOffsetX}
              caseOffsetY={stats.caseOffsetY}
              resizedWidth={stats.resizedWidth}
              naturalWidth={stats.naturalWidth}
              naturalHeight={stats.naturalHeight}
            />
          </div>
          <div
            css={css`
              text-align: center;
            `}
          >
            {preview && (
              <Preview
                image={image}
                sourceOffsetX={naturalOffsetX}
                sourceOffsetY={naturalOffsetY}
                sourceWidth={naturalWidthResize}
                sourceHeight={naturalHeightResize}
                width={caseWidth}
                height={caseHeight}
                setPreview={usePreview}
              />
            )}
            <Button onClick={() => usePreview(true)} disabled={outOfBounds}>
              Preview
            </Button>
            <Button onClick={() => submitImage()} disabled={outOfBounds}>
              Add To Order
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
