import React from 'react';
import { css } from '@emotion/core';
export const Modal = props => (
  <div
    css={css`
      background-color: rgba(0, 0, 0, 0.2);
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  >
    <div
      css={css`
        box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.2);
        padding: 20px;
        border-radius: 20px;
        background-color: #fff;
        display: inline-block;
        margin: auto;
      `}
    >
      {props.children}
    </div>
  </div>
);
