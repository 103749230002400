import React, { useState } from 'react';
import Select from 'react-select';

export const PhoneSelect = props => {
  const { products } = props;
  const [selected, useSelected] = useState();
  const options = products.map(product => ({
    value: product.SKU,
    label: `${product.Name} - $${product.Price}`,
    ...product,
  }));
  const selectOption = selectedOption => {
    useSelected(selectedOption);
    props.setMaskImage({
      id: selectedOption.SKU,
      mask: require(`../../images/templates/${selectedOption.SKU}-mask.svg`),
      width: selectedOption.Width,
      height: selectedOption.Height,
      price: selectedOption.Price,
      name: selectedOption.Name,
    });
  };
  return (
    <div>
      <Select
        value={selected}
        onChange={selectedOption => selectOption(selectedOption)}
        options={options}
      />
    </div>
  );
};
