import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Customiser from '../components/customiser/index';

import { boxStyle } from '../styles';

const CustomPhoneCase = ({ data }) => {
  const products = data.allProducts.edges.map(edge => edge.node);
  // Determining if CSS Masks are available for the customiser.
  // OR if there is no document we are serverside so render anyways for SEO
  const compatibleMasks =
    typeof document === 'undefined' ||
    document.body.style['maskRepeat'] === '' ||
    document.body.style['webkitMaskRepeat'] === '';
  return (
    <Layout>
      <SEO title="Custom Phone Case" />
      <h1
        css={css`
          font-family: 'Montserrat', Helvetica, Arial, sans-serif;
          font-weight: bold;
          margin-top: 10px;
          text-align: center;
        `}
      >
        Custom Phone Case Builder
      </h1>
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <div>
          {compatibleMasks ? (
            <Customiser products={products} />
          ) : (
            <div css={boxStyle}>
              Your browser isn't compatible with our customiser. :`(
              <br />
              Please use a different browser like Chrome or Contact Us.
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProductQuery {
    allProducts {
      edges {
        node {
          SKU
          Name
          Width
          Height
          Price
        }
      }
    }
  }
`;

export default CustomPhoneCase;
