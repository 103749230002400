import React, { useRef, useEffect } from 'react';
import { css } from '@emotion/core';
import { DraggableImage } from './draggable-image';
import { Droppable } from './drop-image';

export const Mask = props => {
  const maskRef = useRef();
  useEffect(() => {
    props.setMask(maskRef);
  });
  return (
    <Droppable setImage={props.setImage}>
      <div
        ref={maskRef}
        css={css`
          background-color: #dcb0ff;
          display: inline-block;
          -webkit-mask-image: url(${props.maskImage});
          mask-image: url(${props.maskImage});
          mask-repeat: no-repeat;
          -webkit-mask-repeat: no-repeat;
          position: relative;
          width: 320px;
          height: 360px;

          ${props.outOfBounds &&
            'background-image: repeating-linear-gradient(135deg, transparent, transparent 15px, #ff4721 15px, #ff4721 20px);'}

          @media only screen and (min-width: 500px) {
            width: 500px;
            height: 562px;
          }
        `}
      >
        <DraggableImage
          parentRef={maskRef}
          image={props.image}
          maskRatio={props.maskRatio}
          caseOffsetX={props.caseOffsetX}
          caseOffsetY={props.caseOffsetY}
          setStats={props.setStats}
        />
      </div>
      {props.outOfBounds && (
        <div
          css={css`
            height: 45px;
            padding: 10px 20px;
            margin-bottom: 10px;
            border-bottom: solid 3px #ff4721;
            background-color: #ff4721;
            position: absolute;
            margin-top: -70px;
            color: #fff;
            margin-left: 40px;
            z-index: 1;
          `}
        >
          The image must cover the whole phone case.
        </div>
      )}
    </Droppable>
  );
};
