import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const Resizer = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: solid 6px #fff;
  background-color: RoyalBlue;
  cursor: nwse-resize;

  @media only screen and (min-width: 480px) {
    width: 17px;
    height: 17px;
    border-radius: 10px;
    border: solid 3px #fff;
  }
`;

const getClientX = e =>
  e.clientX || (e.touches && e.touches.length && e.touches[0].clientX);

const getClientY = e =>
  e.clientY || (e.touches && e.touches.length && e.touches[0].clientY);

export const DraggableImage = props => {
  // State for moving the image
  const [position, usePosition] = useState({ left: 5, top: 5 });
  const [moving, useMoving] = useState(false);
  const [moveOffset, useMoveOffset] = useState({ x: 0, y: 0 });
  // State for resizing the image
  const [resizing, useResizing] = useState(false);
  const [resizeOffset, useResizeOffset] = useState({ x: 0, y: 0, width: 0 });
  const resizeRef = useRef();
  const defaultWidth = 300;
  const [width, setWidth] = useState(defaultWidth);
  // Parent stats
  const parentEl = props.parentRef && props.parentRef.current;
  let parentX = 0;
  let parentY = 0;
  if (parentEl && parentEl.getBoundingClientRect) {
    const pos = parentEl.getBoundingClientRect();
    parentX = pos.x;
    parentY = pos.y;
  }

  const mouseDownMove = e => {
    e.preventDefault();
    e.stopPropagation();
    // Set current cursor position in relation to image
    const pos = e.target.getBoundingClientRect();
    const clientX = getClientX(e);
    const clientY = getClientY(e);
    useMoveOffset({ x: clientX - pos.x, y: clientY - pos.y });
    if (!resizing) {
      useMoving(true);
    }
  };

  const mouseDownResize = e => {
    e.preventDefault();
    e.stopPropagation();
    const clientX = getClientX(e);
    const clientY = getClientY(e);
    useResizeOffset({ x: clientX, y: clientY, width });
    useResizing(true);
  };

  const mouseMove = e => {
    const clientX = getClientX(e);
    const clientY = getClientY(e);
    if (moving) {
      e.preventDefault();
      e.stopPropagation();
      const left = clientX - moveOffset.x - parentX;
      const top = clientY - moveOffset.y - parentY;
      usePosition({
        left,
        top,
      });
      const resizedWidth = e.target.offsetWidth;
      const naturalWidth = e.target.naturalWidth;
      const naturalHeight = e.target.naturalHeight;
      props.setStats({
        left,
        top,
        offsetX: props.caseOffsetX - left,
        offsetY: props.caseOffsetY - top,
        resizedWidth,
        naturalWidth,
        naturalHeight,
        caseOffsetX: props.caseOffsetX,
        caseOffsetY: props.caseOffsetY,
      });
    } else if (resizing) {
      e.preventDefault();
      const newWidth = resizeOffset.width + (clientX - resizeOffset.x);
      if (newWidth !== width) {
        setWidth(newWidth);
        props.setStats({ resizedWidth: newWidth });
      }
    }
  };
  const clearActions = () => {
    useMoving(false);
    useResizing(false);
  };
  useEffect(() => {
    window.addEventListener('touchmove', mouseMove, { passive: false });
    window.addEventListener('touchend', clearActions);
    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('mouseup', clearActions);
    return () => {
      window.removeEventListener('touchmove', mouseMove);
      window.removeEventListener('touchend', clearActions);
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('mouseup', clearActions);
    };
  });
  return (
    <div
      css={css`
        width: ${width}px;
        position: absolute;
        touch-action: none;
      `}
      style={{ left: position.left, top: position.top }}
      onTouchStart={mouseDownMove}
      onMouseDown={mouseDownMove}
    >
      <Resizer
        ref={resizeRef}
        css={css`
          position: absolute;
          bottom: -10px;
          right: -20px;

          @media only screen and (min-width: 480px) {
            bottom: 0px;
            right: -8px;
          }
        `}
        onTouchStart={mouseDownResize}
        onMouseDown={mouseDownResize}
      />
      <img
        css={css`
          width: ${width}px;
          cursor: move;
        `}
        src={props.image}
        alt="Uploaded"
      />
    </div>
  );
};
