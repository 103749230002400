import React from 'react';
import { css } from '@emotion/core';

const getImage = (files, callback) => {
  const imageType = /^image\//;
  const image = files.find(file => imageType.test(file.type));
  const reader = new FileReader();
  reader.onload = event => {
    callback(event.target.result);
  };
  reader.readAsDataURL(image);
};

export const Droppable = props => {
  return (
    <div
      onDrop={e => {
        e.stopPropagation();
        e.preventDefault();
        getImage(Array.from(e.dataTransfer.files), imageResult =>
          props.setImage(imageResult)
        );
      }}
      onDragEnter={e => e.preventDefault()}
      onDragOver={e => e.preventDefault()}
      onDragLeave={e => e.preventDefault()}
    >
      {props.children}
    </div>
  );
};

export const DropImage = props => {
  return (
    <Droppable setImage={props.setImage}>
      <div
        css={css`
          max-width: 500px;
          height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px auto;
          padding: 20px;

          font-size: 16px;
          background-color: #eee;
          border-radius: 20px;
          border: dashed 3px #d0d0d0;
        `}
      >
        <div>Drop your photo here or click here to open the file explorer.</div>
        <input
          type="file"
          onChange={e =>
            getImage(Array.from(e.target.files), imageResult =>
              props.setImage(imageResult)
            )
          }
          css={css`
            width: 500px;
            height: 400px;
            opacity: 0;
            position: absolute;
          `}
        />
      </div>
    </Droppable>
  );
};
