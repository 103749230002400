import React from 'react';
import { css } from '@emotion/core';

const color = css`
  background-color: #ff6f91;
  border: 0;
  color: #ffffff;
`;

const inverseColor = css`
  background-color: #ffffff;
  border: solid 3px #ff6f91;
  color: #ff6f91;
`;

export default props => (
  <button
    css={css`
      position: relative;
      display: inline-block;
      height: 45px;
      padding: 10px 20px;
      margin-bottom: 10px;
      top: 0px;
      border-radius: 25px;
      text-decoration: none;
      cursor: pointer;
      transition: 0.2s ease;

      ${props.inverse ? inverseColor : color}

      ${props.disabled &&
        css`
          opacity: 0.5;
        `}

      ${!props.disabled &&
        css`
          &:hover {
            top: -3px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
          }
        `}


      ${props.cssExtras}
    `}
    {...props}
  >
    {props.children}
  </button>
);
